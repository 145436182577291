<template>
  <div class="MarketDivisionManagement">
    <div class="top">
      <div class="left">
        市场筛选
        <el-select
          v-model="bazaar_value"
          @clear="agencyLists"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in bazaar_options"
            :key="item.comp_name"
            :label="item.comp_name"
            :value="item.id"
            @click.native="agencyLists(item.id)"
          >
          </el-option>
        </el-select>
      </div>
      <div class="right">
        <el-button @click="status = true" type="primary">新增</el-button>
      </div>
    </div>
    <div class="table">
      <el-table
        :data="tableData.data"
        border
        :header-cell-style="{ textAlign: 'center' }"
        :cell-style="{ textAlign: 'center' }"
        style="width: 100%;margin-top:20px"
        stripe
      >
        <el-table-column prop="id" label="市场ID"> </el-table-column>
        <el-table-column prop="comp_name" label="市场名称"> </el-table-column>
        <el-table-column prop="segmentation_title" label="分区名称">
        </el-table-column>
        <el-table-column prop="booth_number" label="摊位数量">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        current-page.sync="1"
        :page-sizes="[10, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="status"
      width="30%"
      @close="titleclick"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="市场名称" prop="name">
          <el-select v-model="ruleForm.mk_id" placeholder="请选择">
            <el-option
              v-for="item in bazaar_options"
              :key="item.id"
              :label="item.comp_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分区名称" prop="name">
          <el-input
            v-model="ruleForm.segmentation_title"
            style="width:217px"
            placeholder="请输入分区名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="摊位数量">
          <el-input-number
            v-model="ruleForm.booth_number"
            :min="1"
            @blur="number"
          ></el-input-number>
        </el-form-item>
        <div style="text-align:right">
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确 认</el-button
          >
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getmarket,
  segmentation,
  POSTmarketManageList
} from '../../../api/layout'
export default {
  name: 'MarketDivisionManagement',
  data () {
    return {
      title: '添加市场分区',
      bazaar_options: '',
      bazaar_value: '',
      tableData: '',
      status: false,
      page: '',
      limit: 10,
      ruleForm: {
        mk_id: '', // 市场id
        segmentation_title: '', // 分区名称
        booth_number: 1 // 摊位数量
      },
      ID: '',
      serchId: '',
      rules: {
        mk_id: [{ required: true, message: '请选择市场', trigger: 'blur' }],
        segmentation_title: [
          { required: true, message: '请输入分区名称', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 市场筛选
    async getMarketSegmentationPage () {
      await getmarket().then(res => {
        this.bazaar_options = res.data
      })
    },
    // 市场分区列表
    getsegmentation ({
      id = this.serchId,
      page = this.page,
      limit = this.limit
    } = {}) {
      segmentation({ page, limit, id }).then(res => {
        this.tableData = res.data
      })
    },
    // 点击市场筛选
    agencyLists (id) {
      this.serchId = id
      this.getsegmentation()
    },
    // 每一页的条数 10/条
    handleSizeChange (val) {
      this.limit = val
      this.getsegmentation()
    },
    // 当前的页码
    handleCurrentChange (val) {
      this.page = val
      this.getsegmentation()
    },
    // 编辑
    handleEdit (row) {
      this.status = true
      this.ID = row.id
      this.title = '编辑市场分区'
      this.bazaar_options.forEach(item => {
        if (item.comp_name === row.comp_name) {
          this.ruleForm.mk_id = item.id
        }
      })
      this.ruleForm.segmentation_title = row.segmentation_title
      this.ruleForm.booth_number = row.booth_number
    },
    // 提交
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const data = {
            ...this.ruleForm
          }
          if (this.title === '添加市场分区') {
            POSTmarketManageList(data).then(res => {
              if (res.code === 200) {
                this.titleclick()
                this.status = false
                this.getsegmentation()
                this.$message.success(`${res.data}`)
              } else {
                this.$message.error(`${res.msg}`)
              }
            })
          } else {
            data.id = this.ID
            POSTmarketManageList(data).then(res => {
              if (res.code === 200) {
                this.titleclick()
                this.status = false
                this.getsegmentation()
                this.$message.success(`${res.data}`)
              } else {
                this.$message.error(`${res.msg}`)
              }
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 计数器
    number () {
      if (!this.ruleForm.booth_number) {
        this.ruleForm.booth_number = 1
      }
    },
    // 清空
    titleclick () {
      this.ID = ''
      this.title = '添加市场分区'
      this.ruleForm = {
        mk_id: '', // 市场id
        segmentation_title: '', // 分区名称
        booth_number: 1 // 摊位数量
      }
    }
  },
  mounted () {
    this.getMarketSegmentationPage()
    this.getsegmentation()
  }
}
</script>

<style lang="less" scoped>
.MarketDivisionManagement {
  .top {
    display: flex;
    justify-content: space-between;
  }
}
.footer {
  text-align: right;
  margin-top: 10px;
}
</style>
